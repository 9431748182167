import React from 'react';
import ComparisonTable, {
  Comparisons,
} from '../components/alternative-to/ComparisonTable';
import HomeHeader, { HomeHeaderProps } from '../components/home/HomeHeader';
import HomeLogos from '../components/home/HomeLogos';
import MarketingLayout from '../components/layout/MarketingLayout';
import PageSection from '../components/ui/PageSection';
import { Competitor } from '../types';

interface ComparisonProps extends HomeHeaderProps {
  competitor: Competitor;
  comparisons: Comparisons;
  features: React.ReactNode;
  header?: string;
  subheader?: string;
  metaTitle?: string;
  metaDescription?: string;
}

const ComparisonTemplate: React.SFC<ComparisonProps> = ({
  competitor,
  comparisons,
  features,
  metaTitle,
  metaDescription,
  header,
  subheader,
}) => (
  <MarketingLayout
    navbarShowRegister={false}
    navbarDark
    metaTitle={metaTitle}
    description={metaDescription}
    noFooterPadding
  >
    <HomeHeader header={header} subheader={subheader} />
    <HomeLogos />
    <PageSection id="features">{features}</PageSection>
    <ComparisonTable
      competitor={competitor}
      comparisons={comparisons}
      registerSource="comparison-import"
    />
  </MarketingLayout>
);

export default ComparisonTemplate;
